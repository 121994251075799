<template>
  <div class="childContainer mediaRight">
    <div class="mediaContainer">
      <img class="media" src="../../../assets/savoirFaire/sf1.jpg" alt="" />
    </div>
    <div class="textContainer">
      <div class="content">
        <h2>"Des mains en or"</h2>
        <p>
          SUN Jewelry a à cœur de défendre le savoir-faire et de le faire valoir
          ; c'est pour cela que nous nous entourons de partenaires passionnés et
          amoureux de ce beau métier qui est le nôtre. Toutes les étapes de
          fabrication sont réalisées en France et notamment à Lyon où est situé
          notre atelier
        </p>
        <p>
          Dessinateur 3D, gemmologue, sertisseur : C'est en s'entourant de
          personnes de confiance que nous irons au bout de nos envies créatives
          et même au-delà.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
h2 {
  font-style: italic;
}
</style>
<template>
  <div class="childContainer mediaRight moreMargin">
    <div class="mediaContainer">
      <img class="media" src="../../../assets/savoirFaire/sf4.jpg" loading="lazy" alt="" />
    </div>
    <div class="textContainer">
      <div class="content">
        <h2>Pierres et Sertissage :</h2>
        <p>
          Cadeau de la nature, SUN Jewelry s'attache à en prendre le plus grand
          soin, autant pendant le sertissage du bijou à l'atelier que de son
          origine en travaillant avec des partenaires gemmologues soucieux de
          leur provenance.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.moreMargin {
  margin: 5% auto !important;
}
</style>
<template>
  <div class="childContainer mediaRight">
    <div class="mediaContainer">
      <img class="media" src="../../../assets/savoirFaire/sf7.jpg" loading="lazy" alt="" />
    </div>
    <div class="textContainer">
      <div class="content">
        <div class="iconContainer">
          <img
            class="starIcon"
            src="../../../assets/inspiration/inspiStar.png"
            alt=""
          />
        </div>
        <p>
          Comme dans les plus grandes maisons de joaillerie, chaque bijou SUN
          Jewelry est contrôlé à la loupe avant la mise en écrin pour vous
          certifier une qualité de travail en accord avec la charte de la
          maison, car pour nous, vous méritez le meilleur.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.iconContainer {
  text-align: center;
  .starIcon {
    width: 90px;
  }
}
</style>